<template>
    <div>
        <bread-crumb :crumbData="['进货订单', '详情']" :chooseCrumbData="2" @close="onClose"></bread-crumb>
        <div style="padding:20px;font-size:14px">
            <div style="text-align: right;">
                <el-button type="success" size="mini">订单发货</el-button>
                <el-button type="primary" size="mini">打印</el-button>
                <el-button type="primary" size="mini">打单完成</el-button>
            </div>
        </div>
        <div style="font-size: 15px;padding:15px">
            <p style="text-align: center;font-size: 30px;">销售开单</p>
            <div style="display: flex; margin: 10px 5px 5px 5px;line-height:24px">
                <div style="flex:1">
                    <p>收货信息：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info.consignee }}{{ info.phone
                    }}
                    </p>
                    <p>收货地址<span style="width: 20px; display: inline-flex">&emsp;</span>{{
                        info.location
                    }}</p>
                    <p>制单日期：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info.create_time }}</p>
                    <p>订单号：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info.order_number }}</p>
                </div>
                <div style="flex:1">
                    <p>配送司机:<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info.delivery }}</p>
                    <p>订单备注:<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info.notes }}</p>
                    <p>运单备注：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info.waybill_notes }}</p>
                </div>
            </div>
            <table class="table">
                <tr>
                    <th v-for="(item, index) in [`序号`, '货号', '品名', '规格', '数量', '进货价', '金额', '备注']" :key="index">{{ item }}
                    </th>
                </tr>
                <tr v-for="(item, index) in tableData" :key="index">
                    <!-- <td>{{ item.product_name }}</td>
                    <td>{{ item.specifications }}</td>
                    <td>{{ item.price }} </td>
                    <td>{{ item.coupon }}</td>
                    <td>{{ item.number }} </td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.sales }}</td>
                    <td>{{ item.sales }}</td> -->
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.product_name }}</td>
                    <td>{{ item.specifications }}</td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.number * item.price }}</td>
                    <td>{{ item.goods_notes }}</td>
                    <!-- <td>回复寄快递顺丰快递</td>
                    <td>回复寄快递顺丰快递</td>
                    <td>回复寄快递顺丰快递</td>
                    <td>回复寄快递顺丰快递</td> -->
                </tr>
                <!-- <tr v-show="tabletd1.length == 0">
                    <td :colspan="7" style="color: #666">没有数据</td>
                </tr> -->
                <tr>
                    <td :colspan="4">合计</td>
                    <td>{{ tableData.reduce((x, y) => parseInt(x.number) + parseInt(y.number)) }}</td>
                    <td>{{ '' }}</td>
                    <td>￥{{ tableData.reduce((x, y) => parseFloat(x.number * x.price) + parseFloat(y.number * x.price)) }}
                    </td>
                    <td></td>
                </tr>
            </table>
            <div style="display: flex; margin: 10px 5px 5px 5px;">

                <div style="flex:1">
                    <p style="text-align:left">&emsp;优惠折扣： <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;已付运费 <span style="width: 20px; display: inline-flex">&emsp;</span>
                    </p>
                    <p style="text-align:left">&emsp;出库时间 <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;司机签字 <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;押桶 <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;购买水桶： <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;欠桶： <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;退桶： <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;兑换水桶： <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p style="text-align:left">&emsp;其他： <span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>回桶数量确认司机签字：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <!-- <p>&emsp;销售站：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.station }}</p>
                    <p>&emsp;配送站：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.station }}</p>
                    <p>&emsp;送货员：<span style="width: 20px; display: inline-flex">&emsp;</span>{{info1.delivery }}</p>
                    <p>&emsp;推广人：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.referrer}}</p>
                    <p>预约时间：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ "" }}</p> -->
                </div>
                <div style="flex:1">
                    <p>支付状态：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>未付运费：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>水站签字：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>库管签字：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>回桶品牌：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>数量：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>回桶品牌：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>数量：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>回桶品牌：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>数量：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>合计：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <p>回桶数量确认库管签字：<span style="width: 20px; display: inline-flex">&emsp;</span></p>
                    <!-- <p>支付状态：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.pay_status }}</p>
                    <p>应收金额：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.sum }}</p>
                    <p>实付金额：<span style="width: 20px; display: inline-flex">&emsp;</span>{{ info1.sum }}</p> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["onClose", "id"],
    data() {
        return {
            info: {

            },
            tableData: []
        }
    },
    async created() {
        // console.log(this.id)
        this.handleQuery()
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        async handleQuery() {
            let id = this.id;
            if (id) {
                let res = (await this.$http.post("/Station/Barrel_Entry/details", { id })).data
                this.info = res;
                this.tableData = res.data || []
                console.log(res.data)
            }
        },
    },
}
</script>
<style scoped lang="less">
p {
    margin: 5px
}

.wrapper {
    display: flex;
    border-bottom: 1px solid #000;
}

.wrapper input {
    display: none;
}

.wrapper label {
    display: inline-block;
    padding: 0 15px;
    background-color: rgb(231, 231, 231);
    color: rgb(0, 0, 0);
    font-weight: bold;
    height: 30px;
}

#home:checked~label.home,
#user:checked~label.user,
#message:checked~label.message,
#collect:checked~label.collect,
#create:checked~label.create {
    color: #fff;
    background-color: rgb(0, 0, 0);
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;

    th {
        padding: 10px 20px;
        border: 1px solid #e5e5e5;
    }

    td {
        padding: 10px 20px;
        border: 1px solid #e5e5e5;
    }
}
</style>
