<template>
  <div>
    <div v-if="isShow && showDetaile == false">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>水站进货订单</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload">
          <i style="font-size: 18px" class="el-icon-refresh-right"></i>
        </el-button>
      </div>

      <div style="padding: 20px 20px 10px">
        <span style="font-size: 12px">创建日期：</span>
        <el-date-picker type="date" v-model="query.firstTime" style="width: 12%"></el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker type="date" v-model="query.lastTime" style="width: 12%"></el-date-picker>
      </div>

      <div style="border-bottom: 1px solid #e5e5e5; padding: 0 20px 20px">
        <span style="font-size: 12px">订单状态：</span>
        <el-select v-model="query.order_status" clearable style="width: 8% !important; margin: 0 5px">
          <el-option v-for="item in indentStatuses" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>

        <span style="font-size: 12px; margin-left: 10px">打单状态：</span>
        <el-select v-model="query.blackmail" clearable style="width: 8% !important; margin: 0 5px">
          <el-option v-for="item in orderStatuses" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>

        <span style="font-size: 12px; margin-left: 10px">选择水站：</span>
        <el-select v-model="query.station" filterable clearable>
          <el-option v-for="item in selectWateres" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>

        <el-input v-model="query.search" placeholder="输入要查询的单号,地址,联系人,手机号"
          style="width: 20%; margin: 0 5px 0 10px"></el-input>
        <el-button type="success" @click="handleQuery()"><i class="el-icon-search"></i>查询</el-button>
      </div>

      <div style="
          display: flex;
          padding: 10px 20px;
          border-bottom: 1px solid #e5e5e5;
        ">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="isShow = !isShow">人工报单</el-button>
        <el-button type="primary" icon="iconfont icon-daochu" size="mini" @click="exportClick">导出</el-button>
      </div>

      <div style="margin: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="create_time" sortable label="操作时间">
          </el-table-column>
          <el-table-column prop="create_time" sortable label="下单时间">
          </el-table-column>
          <el-table-column prop="station" label="水站名称"> </el-table-column>
          <el-table-column prop="consignee" label="收货人"> </el-table-column>
          <el-table-column prop="phone" label="联系电话"> </el-table-column>
          <el-table-column prop="plan_time" sortable label="预计送达时间">
          </el-table-column>
          <el-table-column prop="service_time" sortable label="送达时间">
          </el-table-column>
          <el-table-column prop="driver" label="所属司机">
          </el-table-column>
          <el-table-column prop="delivery" label="配送司机">
          </el-table-column>
          <el-table-column prop="number" sortable label="订单数量">
          </el-table-column>
          <el-table-column prop="payable" sortable label="应付金额">
          </el-table-column>
          <el-table-column prop="order_status" label="订单状态">
          </el-table-column>
          <!-- <el-table-column prop="blackmail" label="打单状态">
          </el-table-column>
          <el-table-column prop="pay_status" label="收款状态">
          </el-table-column> -->
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                ">
                <el-button type="success" size="mini" @click="particulars(scope.row)">详情</el-button>
                <el-button type="info" size="mini" style="margin: 5px 0 0 0" @click="revocation(scope.row)">撤销</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="tableBottom">
          <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
          <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span>
          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div> -->
        <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
          :total="total">
        </pagination>
      </div>
    </div>
    <Detail v-if="showDetaile" :onClose="close" :id="id"></Detail>
    <add-operate v-if="!isShow" @close="close_add"></add-operate>
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue"
import { getDate } from '../../../../until/utilse';
import Detail from "./childrens/detail.vue";
import { xlixs } from "@/until/xlsx";
export default {
  components: {
    addOperate: () => import("./childrens/waterStation_add.vue"), pagination,
    Detail
  },
  data() {
    return {
      total: 0,
      id: 0,
      showDetaile: false,
      query: {
        currentPage: 1,
        currentLength: 10,
        firstTime: "",
        lastTime: "",
        station: "",
        order_status: "",
        blackmail: "",
        search: "",
      },
      isShow: true,
      dateTime: { date1: "", date2: "" },
      indentStatus: "订单状态",
      indentStatuses: ["未确认", "待发货", "已发货", "已完成", "已取消"],
      orderStatus: "已确认",
      orderStatuses: ["未确认", "已确认", "已打印"],
      selectWater: "选择水站",
      selectWateres: [],
      QueryContent: "",

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  async mounted() {
    let res = (await this.$http.post("/Client/commercial_owner/lst")).data.data;
    // console.log(res);
    this.selectWateres = res.map(el => el.enterprise_name)

    this.handleQuery()
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    exportClick() {
      this.$confirm("每次导出最大商品数为10000条？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("/Station/Barrel_Entry/station_export");
          let arr = res.data;
          console.log(arr);
          arr = arr.map((item) => {
            return Object.values(item);
          });
          xlixs(
            arr,
            [
              "创建时间",
              "订单编号",
              "水站名称",
              "收货人",
              "联系电话",
              "预计送达时间",
              "送达时间",
              "配送司机",
              "订单数量",
              "应付金额",
              "备注",
              "商品名称",
              "进货价",
              "订单状态",
              "打单状态",
              "收款状态"
            ],
            "水站进货订单"
          );
        })
        .catch((e) => {
          console.log(e);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    close() {
      this.showDetaile = false
    },
    async particulars({ id }) {
      this.id = id
      this.showDetaile = true
      // console.log(id)
    },
    async revocation({ id }) {
      try {
        await this.$confirm("确认撤销吗");
        let res = (await this.$http.post('/Station/Barrel_Entry/stock_del', { id }).data);
        if (res.status == "success") {
          this.$message.success("撤销成功")
        } else {
          this.$message("撤销失败")
        }
      } catch (e) {
        console.log("取消了");
      }

    },
    async handleQuery(page = 1) {
      let query = this.query;
      query.currentPage = page;
      if (query.firstTime) {
        query.firstTime = getDate(query.firstTime)
      }
      if (query.lastTime) {
        query.lastTime = getDate(query.lastTime)
      }
      let res = (await this.$http.post("/Station/Barrel_Entry/stock_lst", query)).data;
      this.total = res.count
      this.tableData = res.data
    },
    reload() {
      location.reload();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    close_add(data) {
      this.isShow = data;
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-table__header thead th div {
  white-space: nowrap;
}
</style>
